<template>
  <section class="login">
    <img src="@/assets/images/logo-small.svg" class="login__logo" alt="logo" />

    <form @submit.prevent="onSubmitForm" class="login__form">
      <h2 class="login__title">Login</h2>

      <div class="w-100 mb-4">
        <input
          class="login__input"
          :class="{ error: v$.email.$error }"
          type="text"
          placeholder="Почта"
          v-model="state.email"
        />
        <span class="error-warning" v-if="v$.email.$error"
          >* Некорректный e-mail</span
        >
      </div>

      <div class="w-100 mb-4">
        <input
          class="login__input"
          :class="{ error: v$.password.$error }"
          type="password"
          placeholder="Пароль"
          v-model="state.password"
        />
        <span class="error-warning" v-if="v$.password.$error"
          >* Данное поле обязательно</span
        >
      </div>

      <button type="submit" class="login__btn" :disabled="state.isFormLoading">
        <span>Войти</span>
        <div
          v-if="state.isFormLoading"
          class="spinner-border spinner-border-sm ms-2"
          role="status"
        >
          <span class="visually-hidden">Загрузка...</span>
        </div>
      </button>

      <span class="error-warning mt-3" v-show="state.errorMessage">{{
        state.errorMessage
      }}</span>
    </form>
  </section>
</template>

<script>
import { reactive } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default {
  setup() {
    const state = reactive({
      isFormLoading: false,
      errorMessage: null,
      email: null,
      password: null,
    });
    const rules = {
      email: { required, email },
      password: { required },
    };
    const v$ = useVuelidate(rules, state);
    const store = useStore();

    async function onSubmitForm() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      state.isFormLoading = true;
      state.errorMessage = null;

      try {
        await store.dispatch("login", {
          email: state.email,
          password: state.password,
        });
      } catch ({ response }) {
        state.errorMessage = response?.data?.message
          ? response.data.message
          : "Ошибка сервера";
      } finally {
        state.isFormLoading = false;
      }
    }

    return {
      state,
      v$,
      onSubmitForm,
    };
  },
};
</script>